import React, { useMemo } from "react";
import ReactWebChat, {
  createDirectLine,
  createStyleSet,
} from "botframework-webchat";
import "./WebChat.scss";
import { useAtom } from "jotai";
import { botLoadingAtom, directLineTokenAtom } from "../atoms";
//import { activityStatusMiddleware } from './BotActivityDecorator';
let HOST_URL = process.env.REACT_APP_HOST_URL; //URL from env

export const SendButton = () => {
  return (
    <img
      src={HOST_URL + "assets/bp-icon-airplane-paper-bp-silver.svg"}
      alt="Send"
    />
  );
};

export type WebChatProps = {
  store: any;
  className?: string;
  username?: string;
};

export const WebChat = ({ store, className, username }: WebChatProps) => {
  const [token] = useAtom(directLineTokenAtom);
  const [loaded] = useAtom(botLoadingAtom);

  const directLine = useMemo(() => {
    if (token) {
      // console.log(`token: ${token} direct line initialization`);
      return createDirectLine({ token });
    }
  }, [token]);

  // console.log(store?.getState());

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: "Transparent",
        //bubbleBorder: '1px solid #e6e6e6',
        bubbleBorderColor: "#e6e6e6",
        bubbleBorderStyle: "solid",
        bubbleBorderWidth: 1,
        bubbleBorderRadius: 5,
        //linkColor: "#99CC00",
        accent: "#99CC00",
        bubbleFromUserBackground: "#007833",
        bubbleFromUserBorderRadius: 5,
        bubbleFromUserTextColor: "#ffffff",
        //suggestedActionBorder: "1px solid #007833",
        suggestedActionBorderColor: "#007833",
        suggestedActionBorderStyle: "solid",
        suggestedActionBorderWidth: 1,
        suggestedActionTextColor: "#007833",
        suggestedActionBorderRadius: "5px",
        // textContent: {
        //   fontFamily: "'Comic Sans MS', 'Arial', sans-serif",
        // },
        hideUploadButton: true,
        //botAvatarInitials: 'BP',

        //sendButtonSendIcon: <SendButton />,
      }),
    []
  );

  return token && loaded ? (
    <ReactWebChat
      data-testid="web-chat"
      className={`${className || ""} web-chat`}
      directLine={directLine}
      store={store}
      styleSet={styleSet}
      username={username}
      //activityMiddleware={activityMiddleware}
      sendTypingIndicator={true}
    />
  ) : (
    <div
      className={`${className || ""} bp-loader`}
      data-testid="chat-loading-box"
    >
      <img
        src={HOST_URL + "assets/bp-loader.svg"}
        data-testid="chat-loading-dots"
        className="bot-img"
        alt="logo"
      />
      <div className="bp-subText" data-testid="chat-loading-text">
        <strong>Loading BP Virtual Assistant</strong>
      </div>
    </div>
  );
};

export default WebChat;
