import React from "react";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "../auth/auth.config";
import { SignIn } from "../auth/SignIn";

let HOST_PORTAL_URL = "";
let HOST_PORTAL_URLS = process.env.REACT_APP_API_HOST_PORTAL_URL?.split(",");

let IS_HOST_PORTAL_URL = false;
HOST_PORTAL_URLS?.forEach((element) => {
  if (element) {
    element = element.replace("]", "").replace("[", "");

    if (window.location.host.includes(element)) {
      IS_HOST_PORTAL_URL = true;
      HOST_PORTAL_URL = element;
    }
  }
});

// const SSO_TOKEN_REQUIRED = IS_HOST_PORTAL_URL === false;
const SSO_TOKEN_REQUIRED = false;

const AuthWrapper = () => {
  if (!SSO_TOKEN_REQUIRED) {
    return (
      <CookiesProvider>
        <App />
      </CookiesProvider>
    );
  } else {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <MsalProvider instance={msalInstance}>
        <>
          <AuthenticatedTemplate>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignIn />
          </UnauthenticatedTemplate>
        </>
      </MsalProvider>
    );
  }
};

export default AuthWrapper;
