
import { atom } from 'jotai';

//const USE_CHAT_BUBBLE = process.env.REACT_APP_USE_CHAT_BUBBLE === "true";

//--------------------------------------------------------------- !USE_CHAT_BUBBLE ? true:
export type UserData = { 
    conversationId: string,
    minimized: boolean,
    username: string,
    refUserId: string,
    chatStarted: boolean
 };

export const showBotAtom = atom<boolean>(false);
export const reactTokenAtom = atom<string| null>(null);
export const minimizeBotAtom = atom<boolean>(true);
export const directLineTokenAtom = atom<string|null>(null);
export const showNewMessageAtom = atom<boolean>(false);
export const showAnimationAtom = atom<boolean>(false);
export const stopAnimationAtom = atom<boolean>(false);
export const userDataAtom = atom<UserData>({
    conversationId: '',
    minimized: true,
    username: '',
    refUserId: '',
    chatStarted: false
});
export const botLoadingAtom = atom<boolean>(false);