import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import WebChat from "./WebChat";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useAtom } from "jotai";
import { createStore } from "botframework-webchat";
import {
  minimizeBotAtom,
  reactTokenAtom,
  directLineTokenAtom,
  userDataAtom,
  botLoadingAtom,
  showNewMessageAtom,
} from "../atoms";

import { getStore } from "./store";

const USE_CHAT_BUBBLE = process.env.REACT_APP_USE_CHAT_BUBBLE === "true";

const userInfoScript = document.scripts.namedItem("userInfo");
const userInfo =
  userInfoScript != null ? JSON.parse(userInfoScript.text) : null;

const API_URL = process.env.REACT_APP_API_URL; //URL from env
// eslint-disable-next-line no-unused-vars
const HOST_URL = process.env.REACT_APP_HOST_URL || ""; //URL from env

const Extensions = ["pdf", "xml", "json", "png"];

const TOKEN_COOKIE = "bpChatBotToken";
const USER_DATA_COOKIE = "bpmeBotUserData";

export const ChatBox = ({}) => {
  const isInitialized = useRef<boolean>(true);
  const botStore = useRef<any>(null);
  const botDirectLine = useRef<any>(null);
  const [side, setSide] = useState<string>("right");
  const [userName, setUsername] = useState<string>("Guest");
  //const [userId, setUserId] = useState(null);

  const [reactToken] = useAtom(reactTokenAtom);
  const [minimized, setMinimized] = useAtom(minimizeBotAtom);
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useAtom(userDataAtom);
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useAtom(botLoadingAtom);
  const [showNewMessage, setShowNewMessage] = useAtom(showNewMessageAtom);

  const [cookies, setBpChatBotCookie, removeBpChatBotCookie] = useCookies([
    TOKEN_COOKIE,
    USER_DATA_COOKIE,
  ]);

  const handleSideSwitchButtonClick = (e: any) => {
    setSide(side === "left" ? "right" : "left");
  };

  const updateUserDataCookie = (data: any) => {
    setBpChatBotCookie(
      USER_DATA_COOKIE,
      {
        ...cookies.bpmeBotUserData,
        ...data,
      },
      getCookieOptions()
    );
  };

  const handleMinimizeButtonClick = (e: any) => {
    setMinimized(true);
  };

  useEffect(() => {
    updateUserDataCookie({ minimized: minimized });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimized]);

  useEffect(() => {
    if (!minimized && showNewMessage) {
      setShowNewMessage(false);
    }
  }, [showNewMessage, minimized]);

  const getCookieOptions = () => {
    let d = new Date();
    d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
    return {
      path: "/",
      sameSite: true,
      secure: true,
      expires: d,
    };
  };

  const fetchFreshToken = useCallback(async () => {
    let userDetails = {
      email: userInfo.email,
      fname: userInfo.firstname,
      lname: userInfo.lastname,
      roles: userInfo.roles,
    };

    try {
      let resp = await axios.post(
        `${API_URL}/token`,
        JSON.stringify(userDetails),
        {
          headers: {
            accept: `application/json`,
            "Content-Type": "application/json",
            // authorization: `bearer ${reactToken}`,
          },
        }
      );

      setDirectLineToken(resp.data.token);
      setUserData(resp.data);
      setBpChatBotCookie(TOKEN_COOKIE, resp.data.token, getCookieOptions());
      updateUserDataCookie({
        minimized: minimized,
        conversationId: resp.data.conversationId,
        user: resp.data.refUserId,
        username: resp.data.username,
        chatStarted: true,
      });
      // setBpChatBotCookie("minimize", true, getCookieOptions());
      setUsername(resp.data.username);
      setLoaded(true);
      //setUserId(resp.data.refUserId);
      //setChatStarted(false);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactToken]);

  const fetchReconnectToken = useCallback(async () => {
    try {
      const userData = cookies.bpmeBotUserData;
      const cToken = cookies.bpChatBotToken;
      if (cToken && userData) {
        const resp = await axios.post(
          `${API_URL}/token/reconnect`,
          {
            token: cToken,
            conversationId: userData.conversationId,
            username: userData.username,
            user: userData.user,
          },
          {
            headers: {
              accept: `application/json`,
              "Content-Type": "application/json",
            },
          }
        );
        setDirectLineToken(resp.data.token);
        setBpChatBotCookie(TOKEN_COOKIE, resp.data.token, getCookieOptions());
        updateUserDataCookie({
          conversationId: resp.data.conversationId,
          user: resp.data.refUserId,
          username: resp.data.username,
          chatStarted: userData.chatStarted,
          minimized: false,
        });
        setUserData(resp.data);
        setUsername(resp.data.name);
        //setUserId(resp.data.refUserId);
        setLoaded(true);
      } else {
        fetchFreshToken();
      }
    } catch (error: any) {
      if (error.response) {
        removeBpChatBotCookie(TOKEN_COOKIE);
        removeBpChatBotCookie(USER_DATA_COOKIE);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      fetchFreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directLineToken]);

  const createLocalStore = () => {
    const store = () => {
      return getStore(setShowNewMessage);
    };
    botStore.current = store();
  };

  useEffect(() => {
    if (isInitialized.current) {
      if (directLineToken) {
        // if loading from cookies
        isInitialized.current = false;
        fetchReconnectToken();
        createLocalStore();
      } else if (reactToken && !directLineToken) {
        isInitialized.current = false;
        fetchFreshToken();
        createLocalStore();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directLineToken]);

  if (!USE_CHAT_BUBBLE) {
    return (
      <div className="chat-bot">
        <div className={classNames("chat-box")}>
          <WebChat
            data-testid="web-chat"
            className="react-web-chat"
            store={botStore.current}
            username={userName}
          />
        </div>
      </div>
    );
  }

  const handleReloadChat = () => {
    // TODO: restart chat
    // TODO: reset store

    // TODO: reset cookies

    // TODO: reset - redo token
    setDirectLineToken(null);
    botStore.current = null;
    botDirectLine.current = null;
    isInitialized.current = true;
  };

  return (
    <div
      data-testid="chat-box"
      className={classNames("chat-bot", minimized ? "hide" : "")}
    >
      <div className={classNames("chat-box", side)}>
        <header data-testid="chat-header">
          {/* -- removed temporarily */}
          <div className="filler" data-testid="chat-title">
            <span className="bot-title">{"BP virtual assistant"}</span>
          </div>
          {/* <button onClick={handleReloadChat}>Refresh</button> */}
          <button
            data-testid="chat-switch-btn"
            title="Switch"
            className="switch"
            // toggle whether the chat is on the left or right
            onClick={handleSideSwitchButtonClick}
          >
            <span className="ms-Icon ms-Icon--Switch" />
          </button>
          <button
            title="Minimize"
            name="minimize"
            data-testid="chat-minimize-btn"
            className="minimize"
            // toggle minimized state
            onClick={handleMinimizeButtonClick}
          >
            <span className="ms-Icon ms-Icon--ChromeMinimize" />
          </button>
        </header>
        {botStore.current && (
          <WebChat
            data-testid="web-chat"
            className="react-web-chat"
            store={botStore.current}
            username={userName}
          />
        )}
      </div>
    </div>
  );
};

export default ChatBox;
