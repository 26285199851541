import ChatBubble from "./ChatBubble";
import ChatBox from "./ChatBox";
import "./ChatBot.scss";
import React, { useEffect } from "react";
import { useAtom } from "jotai";
import {
  directLineTokenAtom,
  minimizeBotAtom,
  showBotAtom,
  userDataAtom,
} from "../atoms";
import { useCookies } from "react-cookie";

const USE_CHAT_BUBBLE = process.env.REACT_APP_USE_CHAT_BUBBLE === "true";

const TOKEN_COOKIE = "bpChatBotToken";
const USER_DATA_COOKIE = "bpmeBotUserData";

export const ChatBot = () => {
  const [showBot, setShowBot] = useAtom(showBotAtom);
  // eslint-disable-next-line no-unused-vars
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);
  // eslint-disable-next-line no-unused-vars
  const [minimized, setMinimized] = useAtom(minimizeBotAtom);
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useAtom(userDataAtom);

  const [cookies] = useCookies([TOKEN_COOKIE, USER_DATA_COOKIE]);

  useEffect(() => {
    console.log("Chat bubble:", USE_CHAT_BUBBLE);
    setMinimized(USE_CHAT_BUBBLE);
    setShowBot(!USE_CHAT_BUBBLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chat-bot">
      {USE_CHAT_BUBBLE && <ChatBubble />}
      {showBot && <ChatBox />}
    </div>
  );
};

export default ChatBot;
