import React, { useEffect } from "react";
import { ChatBot } from "./ChatBot";
import AzureAdToken from "../auth/AzureAdToken";
import { useAtom } from "jotai";
import { reactTokenAtom } from "../atoms";

let HOST_PORTAL_URL = "";
let HOST_PORTAL_URLS = process.env.REACT_APP_API_HOST_PORTAL_URL?.split(",");

let IS_HOST_PORTAL_URL = false;
HOST_PORTAL_URLS?.forEach((element) => {
  if (element) {
    element = element.replace("]", "").replace("[", "");

    if (window.location.host.includes(element)) {
      IS_HOST_PORTAL_URL = true;
      HOST_PORTAL_URL = element;
    }
  }
});

// const SSO_TOKEN_REQUIRED = IS_HOST_PORTAL_URL === false;
const SSO_TOKEN_REQUIRED = false;

const App = () => {
  const [reactToken, setReactToken] = useAtom(reactTokenAtom);

  useEffect(() => {
    if (!SSO_TOKEN_REQUIRED) {
      setReactToken("Dummy react token");
    }
  }, []);

  return (
    <>
      {SSO_TOKEN_REQUIRED && <AzureAdToken />}
      {reactToken && <ChatBot />}
    </>
  );
};

export default App;
