import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
//import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import AuthWrapper from "./components/AuthWrapper";

ReactDOM.render(
  <React.StrictMode>
    <AuthWrapper />
  </React.StrictMode>,
  document.getElementById("shlchatbotroot")
);

// const root = ReactDOM.createRoot(document.getElementById("bpchatbootroot"));
// root.render(
//   <React.StrictMode>
//     <AuthWrapper />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
