import classNames from "classnames";
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import {
  minimizeBotAtom,
  showBotAtom,
  showNewMessageAtom,
  showAnimationAtom,
  directLineTokenAtom,
  stopAnimationAtom,
} from "../atoms";

const HOST_URL = process.env.REACT_APP_HOST_URL || ""; //URL from env

export const ChatBubble = () => {
  const isInitialized = useRef(true);

  const [showNewMessage] = useAtom(showNewMessageAtom);
  const [showAnimation, setShowAnimation] = useAtom(showAnimationAtom);
  const [stopAnimation, setStopAnimation] = useAtom(stopAnimationAtom);
  const [showBot, setShowBot] = useAtom(showBotAtom);
  const [minimized, setMinimized] = useAtom(minimizeBotAtom);
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);

  const onChatBubbleClick = (e: any) => {
    setMinimized(false);

    if (!showBot) {
      setShowBot(true);
    }
  };

  useEffect(() => {
    if (!directLineToken && isInitialized.current) {
      isInitialized.current = false;
      setTimeout(() => {
        setShowAnimation(true);
      }, 2500);

      setTimeout(() => {
        setShowAnimation(false);
      }, 62500);
    } else {
      setShowAnimation(false);
    }
  }, []);

  if (!minimized) {
    return <></>;
  }

  const onCloseAnimationClick = (event: any) => {
    event.stopPropagation();
    setStopAnimation(true);
  };

  return (
    <button
      data-testid="bubble-button"
      className={classNames("maximize", "container-bubble", {
        sample: showAnimation,
      })}
      // toggle minimized state
      onClick={onChatBubbleClick}
    >
      <span
        className={
          directLineToken
            ? "ms-Icon ms-Icon--MessageFill"
            : "ms-Icon ms-Icon--Message"
        }
      />
      {showNewMessage && minimized && (
        // <span className="red-dot" />
        <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />
      )}

      {!stopAnimation && (
        <span className="overlay">
          <div>
            <span className="closeButton" onClick={onCloseAnimationClick} />
          </div>

          <img
            className={classNames("animation", {
              slider: !showAnimation,
            })}
            alt=""
          />
        </span>
      )}
      {/* <span className={ token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message' } /> */}
    </button>
  );
};
export default ChatBubble;
