import React, { useEffect } from "react";
import { createStore } from "botframework-webchat";
import { useAtom } from "jotai";
import {
  showNewMessageAtom
} from "../atoms";

const Extensions = ["pdf", "xml", "json", "png"];



export const getStore = (setShowNewMessageState: any) => {

  return createStore({}, ({ dispatch }: any) => (next: any) => (action: any) => {
      // console.log(action);
      if (action.type === "WEB_CHAT/SEND_MESSAGE") {
        if (
          action.payload.text.match(
            /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
          )
        ) {
          let num = action.payload.text.match(
            /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
          );
          let match =
            /^(?:3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})[0-9]{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}))$/;
          let rawStr = num[0].replace(/\s/g, "").replace(/-/g, "");
          let matched = rawStr.match(match);
          if (matched && matched.length > 0) {
            action.payload.text = action.payload.text.replace(
              num[0],
              "XXXX "
            );
          }
        }

        if (action.payload.text.length > 1024) {
          action.payload.text =
            "Sorry, I can not accept more that 1024 characters at a time.";
        }
      }
      if (action.type === "DIRECT_LINE/CONNECT_REJECTED") {
        // build a retry connection logic
        let element = document.getElementsByClassName(
          "webchat__basic-transcript__transcript"
        )?.[0];
        element?.classList.remove("bot-loading");
        //TODO: setReload(true);
        //'Failed to connect, DirectLineJS returned 4.'
        //console.log(action.payload);
        if (action.payload.message.includes("Failed to connect,")) {
          //TODO: setBotLoaded(false);
          //TODO:  setResetBot(true);
        }
      }
      if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
         const userInfoScript = document.scripts.namedItem("userInfo");
          const userInfo =
            userInfoScript != null
                ? JSON.parse(userInfoScript.text)
                : null;
        const userID = userInfo.userID;
        const SubAccountDtls = userInfo.SubAccountDtls;
        const firstname = userInfo.firstname;
        const lastname = userInfo.lastname;
        const roles = userInfo.roles;
        
        // Send event to bot with custom data
        let element = document.getElementsByClassName(
          "webchat__basic-transcript__transcript"
        )?.[0];
        element?.classList.add("bot-loading");
        //TODO: setBotLoading(true);
        dispatch({
          type: "WEB_CHAT/SEND_EVENT",
          payload: {
            name: "webchat/join",
            value: {
              language: window.navigator.language,
              userID: userID,
              SubAccountDtls: SubAccountDtls,
              firstname: firstname,
              lastname: lastname,
              Roles: roles,
            },
          },
        });

        //useSendMessage()

        let fileUploadInput = document.querySelector<HTMLInputElement>(
          ".webchat__upload-button--file-input"
        );

        if (fileUploadInput) {
          fileUploadInput.removeAttribute("multiple");
          let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
            "button[title='Upload file']"
          );
          if (fileUploadIconBtn) {
            fileUploadIconBtn.disabled = true;
          }
          
          fileUploadInput.disabled = true;
          const fileTypes = [
            "image/png",
            "image/jpeg",
            "image/jpg",
            "application/pdf",
          ];
          fileUploadInput.setAttribute("accept", fileTypes.join(","));

          fileUploadInput.addEventListener("change", (e) =>
          {
            if(fileUploadInput != null)
            {
              let isLC = fileUploadInput.getAttribute("data-isLiveChat");

              if (fileUploadInput.value !== "" &&
                fileUploadInput.files!.length > 0 &&
                fileUploadInput.files![0]?.size > 4183818
              )
              {
                fileUploadInput.value = "";
                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    name: isLC
                    ? "livechat/file_size_issue"
                    : "webchat/file_size_issue",
                  },
                });
                e.preventDefault();
                return;
              }

              if (
                fileUploadInput.value !== "" &&
                fileUploadInput.files!.length > 0 &&
                !fileTypes.includes(fileUploadInput.files![0]?.type)
              ) {
                fileUploadInput.value = "";
                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    name: isLC
                      ? "livechat/file_type_issue"
                      : "webchat/file_type_issue",
                  },
                });
                e.preventDefault();
                return;
              }

            }

            
          });
        }
      }

      //console.log('action type', action.type, action.payload);
      if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
        if (action.payload.activity.from.role === "bot") {
          //TODO: setBotLoadingState(true);
          let findElement = document
            .getElementsByClassName(
              "webchat__basic-transcript__transcript"
            )?.[0]
            ?.getElementsByTagName("a");

          for (let i = 0; i < findElement?.length; i++) {
            if (
              findElement[i]?.host === window.location.host &&
              !Extensions.includes(findElement[i].href.split(".").pop() || '')
            )
              findElement[i].setAttribute("target", "_self");
          }
          //console.log('removed');
          // setShowNewMessage(true);
          setShowNewMessageState(true);

          //TODO: set Atom
          // useShowNewMessage(true);
          if (
            action.payload.activity.name === "WEB_CHAT/ENABLE_ATTACHMENT_ICON"
          ) {
            
            let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
              "button[title='Upload file']"
            );
            if (fileUploadIconBtn != null)
            { 
              fileUploadIconBtn.disabled = false;
          }

            let fileUploadInput = document.querySelector<HTMLInputElement>(
              ".webchat__upload-button--file-input"
            );
            if (fileUploadInput != null)
            { 
              fileUploadInput.disabled = false;

              if (
                action.payload.activity.islivechat &&
                action.payload.activity.islivechat === "true"
              )
                fileUploadInput.setAttribute("data-isLiveChat", "true");
            }

            

          }
        }

        if (
          action &&
          action.payload &&
          action.payload.activity &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "WEB_CHAT/DISABLE_SUBMIT_BUTTON"
        ) {
          const submitButtonId = action.payload.activity.value;
          const submitButton = document
            .querySelector<HTMLButtonElement>("#" + submitButtonId + " button")
            ;
          if (submitButton) {
            submitButton.setAttribute("disabled", "disabled");
            submitButton.style.visibility = "hidden";
          }
        }

        if (
          action.payload.activity.name === "WEB_CHAT/DISABLE_INPUT_FIELDS"
        ) {
          const disableinputfields = action.payload.activity.disableinputfields;
          if (
            Array.isArray(disableinputfields) &&
            disableinputfields.length > 0
          ) {
              disableinputfields.forEach((elementName) => {
                  console.log("Element : " + elementName);
                  const inputField = document.querySelector<HTMLInputElement>("#" + elementName + "  input");

                  if(inputField != null){
                    inputField.setAttribute("disabled", "disabled");

                  }
              });
          }
        }

        if (
          action.payload.activity.name === "WEB_CHAT/DISABLE_ATTACHMENT_ICON"
        ) {
          let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
            "button[title='Upload file']"
          );
          if (fileUploadIconBtn != null) {
            fileUploadIconBtn.disabled = true;
          }
          let fileUploadInput = document.querySelector<HTMLInputElement>(
            ".webchat__upload-button--file-input"
          );
          if (fileUploadInput != null)
          {
            fileUploadInput.disabled = true;
            fileUploadInput.removeAttribute("data-isLiveChat");
          }
        }
      }
      return next(action);
    });
  };